import {Controller} from "stimulus"

export default class extends Controller {
  initialize() {
    this.element.addEventListener('blur', event => {
      this.modified(event);
    });
  }

  modified(event) {
    let value = this.element.value.trim()
    this.element.classList.toggle('active', value != '');
    this.element.classList.toggle('error', (this.element.required && value == ''));
    if (this.element.checkValidity() == false) {
      this.element.classList.toggle('error', true);
    }
  }

}