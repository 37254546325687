import {Controller} from "stimulus";
import trimCanvas from 'trim-canvas'
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = ['pad', 'field', 'image'];

  initialize() {
    this.originalHeight = this.padTarget.getAttribute('height');
    this.originalWidth = this.padTarget.getAttribute('width');
    this.boundClose = this.close.bind(this);
    document.addEventListener('madedaily.modal.hidden', this.boundClose);

    this.boundShow = this.show.bind(this);
    document.addEventListener('madedaily.modal.shown', this.boundShow);
    
  }

  disconnect() {
    document.removeEventListener('madedaily.modal.hidden', this.boundClose);
    document.removeEventListener('madedaily.modal.shown', this.boundShow);
  }

  blurInput() {
    this.fieldTarget.blur();
  }

  show() {
    this.padTarget.setAttribute('height', this.originalHeight);
    this.padTarget.setAttribute('width', this.originalWidth);
    this.pad = new SignaturePad(this.padTarget);
  }

  close() {
    this.pad.off();
  }

  clear(value) {
    this.pad.clear();
  }

  save() {
    this.cropSignature();
    this.signatureData = this.pad.toDataURL("image/png");
    if (this.hasFieldTarget) {
      this.fieldTarget.value = this.signatureData;
    }
    if (this.hasImageTarget) {
      this.imageTarget.innerHTML = '';
      let image = document.createElement('img');
      image.setAttribute('src', this.signatureData);
      this.imageTarget.appendChild(image);
      this.imageTarget.classList.toggle('active', true);
      if (this.hasFieldTarget) {
        this.fieldTarget.setAttribute('type', 'hidden');
      }
    }
    document.dispatchEvent(new CustomEvent("madedaily.modal.close"));
    document.dispatchEvent(new CustomEvent("madedaily.signature.saved", {detail: {element: this.fieldTarget}}));
  }

  get signature() {
    return this.signatureData;
  }

  cropSignature() {
    trimCanvas(this.padTarget)
  }
}