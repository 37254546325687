import {Controller} from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    this.fp = flatpickr(this.input, {
      allowInput: false,
      altInput: true,
      altInputClass: this.altClass,
      altFormat: this.format,
      enableTime:this.enableTime,
      onChange: (selectedDates, dateStr, instance) => {
        this.input.value = dateStr;
        instance.altInput.classList.toggle('date-active', true);
      },
      onReady: (dateObj, dateStr, instance) => {
        if (this.hasInputTarget) {
          instance.altInput.setAttribute('data-action', 'keyup->calendar#clear');
        }
      }
    });
  }

  clear(event) {
    if (event.code === 'Backspace' || event.code === 'Delete') {
      this.fp.close();
      this.fp.clear();
    }
  }

  get format() {
    let format = this.data.get('format');
    if (format == null) {
      if (this.enableTime) {
        format = 'F J, Y at h:i K';
      }
      else {
        format = 'F J, Y';
      }
    }
    return format;
  }

  get input() {
    if (this.hasInputTarget) {
      return this.inputTarget;
    }
    return this.element;
  }

  get altClass() {
    return this.data.get('css');
  }

  get enableTime() {
    let setting = this.data.get('time');
    if (setting == null) {
      return true;
    }
    if (typeof(setting) === 'string') {
      return setting !== 'false';
    }
    return setting;
  }

  openCalendar() {
    this.fp.open();
  }
}