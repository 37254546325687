import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['modal', 'backdrop'];

  initialize() {
    this.boundClose = this.close.bind(this);
    document.addEventListener('madedaily.modal.close', this.boundClose);
  }

  disconnect() {
    document.removeEventListener('madedaily.modal.close', this.boundClose);
  }

  show(event) {
    event.preventDefault();
    //document.body.classList.toggle('modal-open', true);
    this.element.classList.toggle('modal-open', true)
    this.backdropTarget.style.display = 'block';
    this.backdropTarget.classList.toggle('in', true);
    this.modalTarget.style.display = 'block';
    window.setTimeout(() => { this.fadeIn() }, 10);
    document.dispatchEvent(new CustomEvent("madedaily.modal.shown"));
  }

  fadeIn() {
    this.modalTarget.classList.toggle('in', true);
  }

  close() {
    this.backdropTarget.classList.toggle('in', false);
    this.backdropTarget.style.display = 'none';
    this.modalTarget.classList.toggle('in', false)
    this.modalTarget.style.display = 'none';
    //document.body.classList.toggle('modal-open', false);
    this.element.classList.toggle('modal-open', false)
    document.dispatchEvent(new CustomEvent("madedaily.modal.hidden"));
  }
}