import {Controller} from "stimulus"

export default class extends Controller {
  initialize() {
    if (this.element.getAttribute('type') === 'hidden') {
      this.setMutationObserver();
    }
    else {
      this.element.addEventListener('change', event => {
        this.modified(event);
      });
    }
  }

  modified(event) {
    document.dispatchEvent(new CustomEvent("md.field.updated", {detail: {name: this.name, value:this.value}}));
  }

  setMutationObserver() {
    MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
    let controller = this;
    let observer = new MutationObserver(function (mutations, observer) {
      if (mutations[0].attributeName === "value") {
        console.log('dispatching event');
        document.dispatchEvent(new CustomEvent("md.field.updated", {detail: {name: controller.name, value: controller.value}}));
      }
    });
    observer.observe(this.element, {
      attributes: true
    });
  }

  get name() {
    let name = this.data.get('name');
    if (name == null) {
      name = this.element.getAttribute('id');
    }
    return name;
  }

  get value() {
    return this.element.value.trim();
  }

}