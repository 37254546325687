import {Controller} from "stimulus"
import moment from 'moment-mini';

export default class extends Controller {
  initialize() {
    this.relativeDateValue = null;
    this.setRequired();
  }

  setRequired() {
    this.inputs.forEach(element => {
      if (element.offsetParent == null) {
        if (element.getAttribute('data-required-dependency') == null) {
          element.setAttribute('data-required-dependency', element.required);
        }
        element.required = false;
      }
      else {
        if (element.getAttribute('data-required-dependency') != null) {
          if (element.getAttribute('data-required-dependency') === 'true') {
            element.required = true
          }
        }
      }
    });
  }

  checkDependency(event) {
    if (event.detail.name === this.data.get('field')) {
      this.toggle(this.valueMatches(event.detail.value));
    }
  }

  toggle(showing) {
    this.element.style.display = showing ? 'block' : 'none';
    this.setRequired();
  }

  valueMatches(value) {
    if (this.data.get('date-type') === 'relative') {
      return this.matchRelativeDate(value);
    }
    return this.matchStaticValue(value);
  }

  matchStaticValue(value) {
    try {
      let matches = false;
      switch (this.data.get('type')) {
        case 'gt':
          matches = moment(value).isAfter(this.data.get('value'));
          break;
        case 'lt':
          matches = moment(value).isBefore(this.data.get('value'));
          break;
        case 'ne':
          matches = (value !== this.data.get('value'));
          break;
        default:
          matches = (value === this.data.get('value'))
      }
      return matches;
    }
    catch(error) {
      console.log(error);
      return false;
    }
  }

  matchRelativeDate(value) {
    try {
      let matches = false;
      switch(this.data.get('type')) {
        case 'gt':
          if (this.relativeDate.isAfter()) {
            matches = moment(value).isAfter(this.relativeDate);
          } else {
            matches = moment(value).isBefore(this.relativeDate);
          }
          break;
        case 'lt':
          if (this.relativeDate.isAfter()) {
            matches = moment(value).isBefore(this.relativeDate);
          } else {
            matches = moment(value).isAfter(this.relativeDate);
          }
          break;
        case 'ne':
          matches = !moment(value).isSame(this.relativeDate, 'day');
          break;
        default:
          matches = moment(value).isSame(this.relativeDate, 'day');
      }
      return matches;
    }
    catch(error) {
      console.log(error);
      return false;
    }
  }

  get relativeDate() {
    if (this.relativeDateValue == null) {
      let [number, unit, window] = this.data.get('value').split(':');
      if (window === 'ago') {
        this.relativeDateValue = moment().subtract(parseInt(number), unit);
      } else {
        this.relativeDateValue = moment().add(parseInt(number), unit);
      }
    }
    return this.relativeDateValue;
  }

  get inputs() {
    return this.element.querySelectorAll("input,select,textarea");
  }
}